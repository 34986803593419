import styled, { css } from "styled-components"

export interface UnstyledAnchorProps {
  isUnderlined?: boolean
}

export const unstyledAnchor = ({ isUnderlined }: UnstyledAnchorProps) => css`
  color: inherit;
  cursor: pointer;
  text-decoration: ${isUnderlined ? "underline" : "none"};
`

export const UnstyledAnchor = styled.a<UnstyledAnchorProps>`
  ${unstyledAnchor}
`
