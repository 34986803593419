import { ExternalLink } from "lib/navigation/Link/ExternalLink"
import { InternalLink } from "lib/navigation/Link/InternalLink"
import React from "react"
import { youTubeDevChannel } from "shared/externalResources"
import { Path } from "./Path"

interface NavigationItemProps {
  name: string
}

interface Props {
  renderItem: (props: NavigationItemProps) => React.ReactNode
}

export const PrimaryNavigation = ({ renderItem }: Props) => {
  return (
    <>
      <ExternalLink to={youTubeDevChannel}>
        {renderItem({ name: "YouTube Dev" })}
      </ExternalLink>
      <InternalLink to={Path.Blog}>
        <>{renderItem({ name: "Posts" })}</>
      </InternalLink>
    </>
  )
}
